/**
Opens modal on target click, then start playing video.
Closes modal when user clicks outside of modal div, and resets video
to the beginning.
**/

/* Open modal and starts video when target is clicked */
$('.js-modal-trigger').on('click', function(e) {
  e.preventDefault();
  // playerId on target identifies which modal to open
  // it matches modal id and video_url ?player_id so correct video is targeted
  let playerId = $(this).attr('data-player-id');
  let $modal = $(`#${playerId}`);
  let iframe = $modal.find('iframe')[0];

  // play video
  vimeoPost('play', null, iframe);

  // show modal
  $modal.addClass('active');
});

/* Close modal and unload video so it resets to the beginning */
$('.icon--close, .modal__overlay').on('click', function(e) {
  e.preventDefault();
  e.stopPropagation();

  // need to get .modal__overlay but they might have clicked the close button
  let $modal = $(this);
  if ($(this).hasClass('icon--close')) {
    $modal = $(this).closest('.modal__overlay');
  }
  let iframe = $modal.find('iframe')[0];

  // hide modal
  $modal.removeClass('active');

  // unload video so it will start from beginning next time
  vimeoPost('unload', null, iframe);
});

/**
This function allows us to post events to the vimeo video
See other events at https://github.com/vimeo/player.js#events
**/
function vimeoPost(action, value, target) {
  let data = { method: action };
  if (value) {
    data.value = value;
  }
  target.contentWindow.postMessage(
    JSON.stringify(data),
    'https://player.vimeo.com'
  );
}

/* This sets up the eventlisteners and listens for messages from Vimeo player */
window.addEventListener('message', function(messageEvent) {
  // Don't log events unless from vimeo player
  if (messageEvent.origin !== 'https://player.vimeo.com') return;
  const { data } = messageEvent;
  const message = typeof(data) === 'string' ? JSON.parse(data) : data;
  const $modal = $(`#${message.player_id}`);
  const iframe = $modal.find('iframe')[0];

  if (message.player_id == undefined) return;

  if (message.event === 'ready') {
    vimeoPost('addEventListener', 'loaded', iframe);
    vimeoPost('addEventListener', 'play', iframe);
    vimeoPost('addEventListener', 'pause', iframe);
    // 'ended' event fires after a video finishes
    vimeoPost('addEventListener', 'ended', iframe);
    vimeoPost('addEventListener', 'unload', iframe);
    vimeoPost('addEventListener', 'error', iframe);
  }
  // Track in NR insights - e.g. SELECT * FROM PageAction WHERE actionName='modalVideo'
  if (window.newrelic && ['play', 'ended', 'finish', 'error'].indexOf(message.event) > -1) {
    window.newrelic.addPageAction(
      'modalVideo',
      { event: message.event,
        eventVersion: 0.1,
        success: `${message.event !== 'error'}`,
        message: message }
    );
  }
}, false);
